import * as React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";

import { breakpoints, colors } from "../styles/__variables";
import DefaultLayout from "../layouts/Default";
import RoundContent from "../components/RoundContent";
import TwoThirdsLayout from "../layouts/TwoThirdsLayout";
import Wrapper from "../layouts/Wrapper";
import Title from "../components/Title";
import Paragraph from "../components/Paragraph";

const styles = {
  backgroundLeft: colors.blue.background,
  backgroundRight: "url(/assets/images/background/photocase_295550.jpg)",
  navColor: colors.grey.text,
  navColorDesktop: colors.grey.base,
};

const Kontakt = () => (
  <DefaultLayout styles={styles}>
    <Helmet
      title="Datenschutz – Rundum HR"
      link={[
        {
          rel: "shortcut icon",
          type: "image/png",
          href: `./assets/favicon/android-chrome-192x192.png`,
        },
      ]}
    />
    <TwoThirdsLayout styles={styles}>
      <div>
        <Wrapper>
          <Title color={colors.grey.base}>Datenschutz</Title>
          <Paragraph spaced color={colors.grey.text}>
            <b>Datenschutzerklärung</b>
            <p>
              Die RundumHR GmbH ist Anbieterin von Beratungsdienstleistungen.
              Sie ist verantwortlich für die Webseite www.rundumhr.ch sowie für
              die darauf angebotenen Dienstleistungen und somit verantwortlich
              für die Erhebung, Verarbeitung und Nutzung Ihrer persönlichen
              Daten und die Vereinbarkeit der Datenbearbeitung mit dem
              anwendbaren Datenschutzrecht.
            </p>
            <br />
            <p>
              Ihr Vertrauen ist uns wichtig, darum nehmen wir das Thema
              Datenschutz ernst und achten auf entsprechende Sicherheit.
              Selbstverständlich beachten wir die gesetzlichen Bestimmungen des
              Bundesgesetzes über den Datenschutz (DSG), der Verordnung zum
              Bundesgesetz über den Datenschutz (VDSG), des Fernmeldegesetztes
              (FMG) und andere gegebenenfalls anwendbare datenschutzrechtliche
              Bestimmungen des schweizerischen Rechts. Damit Sie wissen, ob und
              wenn ja, welche personenbezogenen Daten wir von Ihnen erheben und
              für welche Zwecke wir sie verwenden, nehmen Sie bitte die
              nachstehenden Informationen zur Kenntnis.
            </p>
            <br />
            <b>Aufruf unserer Website </b>
            <p>
              Logfiles: Beim Zugriff auf unsere Website werden keine Logfiles
              erstellt. Insbesondere wird die IP-Adresse des Besuchers nicht
              gespeichert. Cookies: Beim Besuch der Website werden keine Cookies
              erstellt. Tracking: Wir setzen in unserer Website keine
              Trackingwerkzeuge ein. Externe Ressourcen: Die Website kann
              weiterführende Links zu anderen Websites enthalten. Eine
              Datenübertragung erfolgt nur, wenn Sie diesen Links auch folgen.
            </p>
            <br />
            <b>Kundenbeziehung</b>
            <p>
              Wenn Sie in mit uns eine Kundenbeziehung eingehen und Bestellungen
              tätigen, benötigen wir für die Abwicklung des Vertrags folgende
              Daten:
              <br />
              Firmenname <br />
              Vor- und Nachname <br />
              Rechnungsadresse (und falls abweichend Lieferadresse) <br />
              Angaben im Rahmen der Zahlung (abhängig von der gewählten
              Zahlungsmethode)
            </p>
            <br />
            <p>
              Sofern in dieser Datenschutzerklärung nicht anders festgehalten
              bzw. Sie dazu nicht gesondert eingewilligt haben, werden wir die
              vorgenannten Daten nur benutzen, um den Vertrag abzuwickeln,
              namentlich um Ihre Bestellungen zu bearbeiten, die bestellten
              Produkte und Dienstleistungen auszuliefern und die korrekte
              Zahlung sicherzustellen. Wenn Sie uns personenbezogene Daten
              anderer Personen zur Verfügung stellen, geben Sie uns
              personenbezogene Daten des Empfängers bitte nur dann bekannt, wenn
              Sie dazu gemäss den geltenden Datenschutzgesetzen berechtigt sind
              und wenn die andere Person damit einverstanden ist, dass Sie uns
              die personenbezogenen Daten für die Zwecke der Verarbeitung zur
              Verfügung stellen. Die Rechtsgrundlage der Datenverarbeitung zu
              diesem Zweck liegt in der Erfüllung eines Vertrages.
            </p>
            <br />
            <b>Weitergabe der Daten an Dritte</b>
            <p>
              Wir geben Ihre personenbezogenen Daten nur weiter, wenn Sie
              ausdrücklich eingewilligt haben, hierfür eine gesetzliche
              Verpflichtung besteht oder dies zur Durchsetzung unserer Rechte,
              insbesondere zur Durchsetzung von Ansprüchen aus dem
              Vertragsverhältnis, erforderlich ist.{" "}
            </p>
            <br />
            <b>Übermittlung von personenbezogenen Daten ins Ausland</b>
            <p>Unsere Daten werden auf Microsoft OneDrive gespeichert. </p>
            <br />
            <b>Kontaktaufnahme und Kommunikation per E-Mail</b>
            <p>
              Über unsere Website mit der als Link bereitgestellte
              E-Mail-Adresse können wir kontaktiert werden. In diesem Fall
              werden Ihre mit der Anfrage übermittelten personenbezogenen Daten
              (in jedem Fall Vor- und Nachname und E-Mail-Adresse) sowie Ihre
              Nachricht gespeichert. Die Verarbeitung dieser personenbezogenen
              Daten dient uns zur Bearbeitung der Kontaktaufnahme und Ihres
              Anliegens. Rechtsgrundlage für die Verarbeitung dieser Daten, die
              im Zuge einer Übersendung einer Anfrage übermittelt werden, ist
              ein berechtigtes Interesse von uns als Verantwortliche an der
              Kommunikation mit den Nutzern unserer Website. Zielt die Anfrage
              auf den Abschluss eines Vertrags ab, so ist die zusätzliche
              Rechtsgrundlage die Erfüllung eines Vertrags (bzw. vorvertragliche
              Massnahmen hierzu).
            </p>
            <br />
            <p>
              Falls Sie nicht damit einverstanden sind, dass wir Ihnen im Rahmen
              einer Kundenbeziehung Dokumente per E-Mail zustellen, teilen Sie
              uns dies bitte mit, sobald eine Kundenbeziehung eingegangen wurde.{" "}
            </p>
            <br />
            <b>Datensicherheit</b>
            <p>
              In enger Zusammenarbeit mit unserem Lieferanten bemühen wir uns,
              unsere Systeme und Datenbanken so gut wie möglich vor fremdem
              Zugriff, Datenverlust, Missbrauch oder vor Fälschung zu schützen.
              Wir bedienen uns geeigneter technischer und organisatorischer
              Sicherheitsmassnahmen, um Ihre bei uns gespeicherten persönlichen
              Daten gegen Manipulation, teilweisen oder vollständigen Verlust
              und gegen unbefugten Zugriff Dritter zu schützen. Unsere
              Sicherheitsmassnahmen werden entsprechend der technologischen
              Entwicklung fortlaufend verbessert.
            </p>
            <br />
            <p>
              Auch den unternehmensinternen Datenschutz nehmen wir sehr ernst.
              Unsere Teammitglieder und die von uns beauftragten
              Dienstleistungsunternehmen sind von uns zur Verschwiegenheit und
              zur Einhaltung der datenschutzrechtlichen Bestimmungen
              verpflichtet worden.
            </p>
            <br />
            <b>Aufbewahrung von Daten</b>
            <p>
              Wir speichern personenbezogene Daten nur so lange, wie es
              erforderlich ist, um die weiteren Bearbeitungen im Rahmen unseres
              berechtigten Interesses sicherzustellen. Vertragsdaten werden von
              uns länger aufbewahrt, da dies durch gesetzliche
              Aufbewahrungspflichten vorgeschrieben ist. Aufbewahrungspflichten,
              die uns zur Aufbewahrung von Daten verpflichten, ergeben sich aus
              Vorschriften der Rechnungslegung und aus steuerrechtlichen
              Vorschriften. Gemäss diesen Vorschriften sind geschäftliche
              Kommunikation, geschlossene Verträge und Buchungsbelege bis zu
              zehn Jahren aufzubewahren.{" "}
            </p>
            <br />
            <b>
              Ihr Recht auf Auskunft, Berichtigung, Löschung und Einschränkung
              der Verarbeitung
            </b>
            <p>
              Sie haben das Recht, über die personenbezogenen Daten, die von uns
              über Sie gespeichert werden, auf Antrag Auskunft zu erhalten.
              Zusätzlich haben Sie das Recht auf Berichtigung unrichtiger Daten
              und das Recht auf Löschung Ihrer personenbezogenen Daten, soweit
              dem keine gesetzliche Aufbewahrungspflicht oder ein
              Erlaubnistatbestand, der uns die Verarbeitung der Daten gestattet,
              entgegensteht.
            </p>
            <br />
            <p>
              Sie können uns für die vorgenannten Zwecke über die E-Mail-Adresse
              andrea.rechsteiner@rundumhr.ch erreichen. Für die Bearbeitung
              Ihrer Gesuche können wir, nach eigenem Ermessen, einen
              Identitätsnachweis verlangen.
            </p>
            <br />
            <b>Recht auf Beschwerde bei einer Datenschutzaufsichtsbehörde</b>
            <p>
              Sie haben das Recht, sich jederzeit bei einer
              Datenschutzaufsichtsbehörde zu beschweren.
            </p>
            <br />
            <b>Auskunft und Kontakt </b>
            <p>
              Sie können uns für die vorgenannten Zwecke über die E-Mail-Adresse
              andrea.rechsteiner@rundumhr.ch erreichen. Für die Bearbeitung
              Ihrer Gesuche können wir, nach eigenem Ermessen, einen
              Identitätsnachweis verlangen. Wenn Sie Fragen zum Datenschutz auf
              unserer Website sowie generell zu den Datenbearbeitungen durch uns
              haben, Auskünfte verlangen oder die Löschung Ihrer Daten
              beantragen möchten und Fragen hinsichtlich der Verarbeitung Ihrer
              persönlichen Daten haben, senden Sie uns bitte Ihre Anfrage inkl.
              einer Ausweiskopie an unseren Datenschutzbeauftragten.
            </p>
            <br />
            <p>
              Unsere Auskunft erhalten Sie kostenlos innerhalb von 30 Tagen
              schriftlich per Post zugestellt.
            </p>
            <br />
            <b>Änderungen der Datenschutzhinweise</b>
            <p>
              Die vorliegenden Datenschutzhinweise werden möglicherweise
              gelegentlich aktualisiert, da wir unsere Dienstleistungen
              kontinuierlich entwickeln und optimieren. Hierzu wird die jeweils
              neueste Version auf unserer Website veröffentlicht.
            </p>
            <br />
            <p>1. September 2023</p>
          </Paragraph>
        </Wrapper>
      </div>
    </TwoThirdsLayout>
  </DefaultLayout>
);

export default Kontakt;
